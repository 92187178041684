import React, { useMemo } from 'react';
import c from 'classnames';
import { Button, ConfigProvider } from 'antd';
import { NextImage, Scrollbar } from '@/components';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';
import type { CommonProps } from '../../types';
import styles from './index.module.less';

/**
 * 收起样式
 * @param param
 * @returns
 */
const PackUp: React.FC<CommonProps> = ({
    onRef,
    recommendList = [],
    contrastList = [],
    onOpen,
    onAddItem,
    onDelItem,
    onGoToDetail,
    onGoToContrast,
    setContrastList,
}) => {
    const noData = useMemo(() => contrastList.length === 0, [contrastList]);
    const threeData = useMemo(() => contrastList.length === 3, [contrastList]);

    return (
        <div className={styles.packup}>
            <div className={styles.header}>
                <p className={styles.to} title={intl.get('Broker.BrokerComparison').d('交易商对比')}>
                    {intl.get('Broker.BrokerComparison').d('交易商对比')}
                </p>
                <p className={styles.tt} title={intl.get('Broker.UpToThree').d('最多可添加3个交易商')}>
                    {intl.get('Broker.UpToThree').d('最多可添加3个交易商')}
                </p>

                <div className={styles.openBtn} onClick={() => onOpen(true)}>
                    <span className={c('iconfont', 'icon-jiantou', styles.icon, styles.left)} />
                </div>
            </div>

            <div className={styles.contrast}>
                {contrastList.length !== 0 && (
                    <ul className={styles.contrastList}>
                        {contrastList.map((item: any) => {
                            const { id, logo, name_abbreviation } = item;
                            return (
                                <li key={id}>
                                    <div className={styles.logo}>
                                        <NextImage
                                            className={styles.img}
                                            src={logo}
                                            layout="fill"
                                            alt={name_abbreviation}
                                            place="logo_a"
                                            onClick={() => onGoToDetail(item)}
                                        />

                                        <span
                                            onClick={() => onDelItem(String(id))}
                                            className={c(styles.closeIcon, 'iconfont', 'icon-shanchu')}
                                        />
                                    </div>

                                    <p
                                        className={styles.name}
                                        title={name_abbreviation}
                                        onClick={() => onGoToDetail(item)}
                                    >
                                        {name_abbreviation}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                )}

                {contrastList.length < 3 && (
                    <div className={styles.selBroker}>
                        <div
                            className={styles.addBtn}
                            onClick={() => {
                                onOpen(true);
                                setTimeout(() => {
                                    onRef?.current?.onOpen?.(true);
                                });
                            }}
                        >
                            <span className={c(styles.addIcon, 'iconfont', 'icon-jiahao')} />
                        </div>

                        <p className={styles.tip}>{intl.get('Broker.AddTrader').d('添加交易商')}</p>
                    </div>
                )}

                <div className={styles.contrastBtnBox}>
                    <ConfigProvider
                        theme={{
                            token: { colorPrimary: '#2C36DE' },
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={onGoToContrast}
                            className={c({ [styles.contrastBtn]: true, [styles.noData]: noData })}
                        >
                            {intl.get('Broker.StartCompare').d('开始对比')}
                        </Button>
                    </ConfigProvider>
                </div>

                <div className={styles.clearBox}>
                    <span
                        className={c({ [styles.clearBtn]: true, [styles.clearBtnHover]: !noData })}
                        onClick={() => setContrastList([])}
                    >
                        <span className={c(styles.clearIcon, 'iconfont', 'icon-clear')} />
                        <span>{intl.get('Broker.ClearAllBroker').d('清空所有交易商')}</span>
                    </span>
                </div>
            </div>
            {recommendList.length !== 0 && (
                <>
                    <div className={styles.midTip} title={intl.get('Broker.RecommendedComparison').d('推荐对比')}>
                        {intl.get('Broker.RecommendedComparison').d('推荐对比')}
                    </div>
                    <div className={styles.recBox}>
                        <Scrollbar
                            noScrollX
                            trackYClassName={styles.recScrollTrackY}
                            thumbYClassName={styles.recScrollThumbY}
                        >
                            <ul className={styles.recList}>
                                {recommendList.map((item: any) => {
                                    const { id, logo, name_abbreviation, seo_url } = item;
                                    return (
                                        <li key={id} title={name_abbreviation}>
                                            <a
                                                className={styles.logo}
                                                href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                                target="_blank"
                                            >
                                                <NextImage
                                                    src={logo}
                                                    alt={name_abbreviation}
                                                    place="logo_a"
                                                    width={54}
                                                    height={54}
                                                />
                                            </a>

                                            <div
                                                className={c({ [styles.addBtn]: true, [styles.addBtnDis]: threeData })}
                                                onClick={() => !threeData && onAddItem(item)}
                                            >
                                                <span
                                                    className={c({
                                                        [styles.addIcon]: true,
                                                        [styles.addIconDis]: threeData,
                                                        iconfont: true,
                                                        'icon-jiahao': true,
                                                    })}
                                                />
                                                <span>{intl.get('Search.Compared').d('对比')}</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Scrollbar>
                    </div>
                </>
            )}
        </div>
    );
};

export default PackUp;
